
import { mapActions, mapGetters } from 'vuex'
// import AdaptiveImage from '~/components/atoms/AdaptiveImage'

export default {
    name: 'MobileMenu',

    components: {
      // AdaptiveImage
    },

    props: {
      value: {
        type: Boolean,
        required: true
      },
    },

    data () {
      return {
        drawer: this.value,
      }
    },

    computed: {
      isHome () {
        return this.$route.path === '/'
      },
      processedTabs () {
        return this.providercategoriesV2 ? this.providercategoriesV2.map((category, index) => {
          return { ...category, tab: index }
        }) : []
      },
      ...mapGetters({ providercategoriesV2: 'providercategoriesV2/providercategoriesV2' })
    },

    watch: {
      drawer (newVal) {
        if (!newVal) {
          this.$emit('close')
        }
      },
      value (newVal) {
        this.drawer = newVal
      },
    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    mounted () {

    },

    methods: {
      externalRedirection(location) {
        window.location.href = location
      },
      goTo(item) {
        this.$router.push(`/${item.slug}/${item.categories.length > 0 ? `${item.categories[0].slug}` : ''}`)
      },
      redirect () {
        this.$router.push('/faqs')
      },
      ...mapActions('providers', ['setOfferingsTab'])
    }
  }
