
// Commenting this code because of: https://sportblx.atlassian.net/browse/SP-1
import { mapState } from 'vuex'
import { marked } from 'marked'
import FooterLinks from '~/components/molecules/FooterLinks'
import SocialButtons from '~/components/molecules/SocialButtons'

export default {
    name: 'SiteFooter',

    components: {
      FooterLinks,
      SocialButtons
    },

    props: {
      isHome: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    data () {
      return {
        companyCard: null,
        contactCard: null,
        disclaimerCard: null,
        socialMediaCard: null,
        links: false,
        isHydrated: false,
        imageError: false
      }
    },

    computed: {
      companyContact() {
        return this.textCompanyEmail + '\n ' + this.textCompanyPhone
      },
      companyWidth() {
        return this.$vuetify.breakpoint.mdAndUp ? '35%' : '100%'
      },
      disclaimerCardMarkdown() {
        return marked(this.disclaimerCard.richDescription.input)
      },
      breakpoint () {
        return this.isHydrated ? this.$vuetify.breakpoint : { mdAndUp: false, sm: false }
      },
      ...mapState('sportblx', [
        'sectionFooter',
        'textFooterLogo',
        'textCompanyEmail',
        'textCompanyPhone'
      ]),
      footerStyle() {
        const defaultStyles = {
          backgroundColor: '#000 !important',
          opacity: 1,
          color: '#fff !important',
        }

        if (
          !this.sectionFooter ||
          !this.sectionFooter.styles ||
          this.sectionFooter.styles.disabled
        ) {
          return defaultStyles
        }

        return {
          'background-color': JSON.parse(this.sectionFooter.styles.backgroundColor).hex ?? defaultStyles.backgroundColor,
          opacity: this.sectionFooter.styles.opacity ?? defaultStyles.opacity,
          color: `${JSON.parse(this.sectionFooter.styles.textColor).hex} !important` ?? defaultStyles.color,
        }
      },
    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    mounted () {
      if (this.sectionFooter && this.sectionFooter.cardsCarousel) {
        this.sectionFooter.cardsCarousel.forEach((cardCarousel) => {
          if (!cardCarousel.enabled || !cardCarousel.title || cardCarousel.title.hidden || !cardCarousel.title.input) {
            return null
          }

          const title = cardCarousel.title.input.toUpperCase()
          if (title.includes('COMPANY')) {
            this.companyCard = cardCarousel
          } else if (title.includes('SOCIAL')) {
            this.socialMediaCard = cardCarousel
          } else if (title.includes('CONTACT')) {
            this.contactCard = cardCarousel
          } else if (title.includes('DISCLAIMER')) {
            this.disclaimerCard = cardCarousel
          }
        })
      }
      this.isHydrated = true
    },

    methods: {
      handleImageError () {
        this.imageError = true
      }
    }
  }
