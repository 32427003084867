
  import { mapActions, mapState } from 'vuex'
  import MenuButton from '@@/components/molecules/MenuButton'

  export default {
    name: 'TopNavigation',
    components: {
      MenuButton
    },
    props: {
      isTransparent: {
        type: Boolean,
        required: false,
        default: true
      },
      menuDrawer: {
        type: Boolean,
        required: true
      },
      banner: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    async fetch () {
      await this.$store.dispatch('sportblx/getAppSettings')
    },
    data() {
      return {
        addedEvent: false,
        evtNavOfferings: 'offerings-redirect',
        signUpUrl: this.$config.SIGNUP_URL,
        drawer: this.menuDrawer,
        loaded: false,
        logoError: false,
      }
    },
    computed: {
      ...mapState('sportblx', ['textCompanyLogo']),
    },
    watch: {
      drawer() {
        this.$emit('menu-change', this.drawer)
      },
      isTransparent: {
        handler() {
          if (!this.loaded) {
            return false
          }

          this.checkScrollEvent()
        },
        immediate: true,
        deep: true
      },
    },
    mounted () {
      this.loaded = true

      this.checkScrollEvent()
    },
    methods: {
      checkScrollEvent() {
        if (this.isTransparent && !this.addedEvent) {
          window.addEventListener('scroll', this.handleScroll)
          this.addedEvent = true
        } else if (!this.isTransparent && this.addedEvent) {
          window.removeEventListener('scroll', this.handleScroll)
          this.addedEvent = false
        }
      },
      externalRedirect(location) {
        window.open(location, '_blank')
      },
      goTo(item) {
        const subcategorySlug = item.categories.length > 0 && item.categories[0].slug ? item.categories[0].slug : ''
        this.$router.push(`/${item.slug}/${subcategorySlug}`)
      },
      handleImageError(e) {
        this.logoError = true
      },
      handleScroll (event) {
        if (this.$route.name === 'category-subcategory-slug' && this.$route.params.slug !== undefined) {
          document.getElementById('topNav').classList.add('backgroundBar')
          window.removeEventListener('scroll', this.handleScroll)
          return false
        }

        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
          document.getElementById('topNav').classList.add('backgroundBar')
        } else {
          document.getElementById('topNav').classList.remove('backgroundBar')
        }
      },
      ...mapActions('providers', ['setOfferingsTab'])
    }
  }
