
import TST from '~/components/molecules/TitleSubTitle'
import Button from '~/components/atoms/Button'

export default {
    name: 'Error',

    components: {
      Button,
      TST
    },

    props: {
      notFound: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    data () {
      return {
      }
    },

    computed: {
      title() {
        return this.notFound ? 'Page not found' : 'Something went wrong'
      },
      subtitle() {
        return this.notFound ? 'The page you are looking for does not exist' : 'We couldn\'t load this screen, try to refresh your browser'
      },
      img() {
        return this.notFound ? '/404.png' : '/error.png'
      }
    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    mounted () {
    },

    methods: {
      refresh() {
        location.reload()
      }
    }
  }
