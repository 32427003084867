
import CategoryCard from '~/components/molecules/CategoryCard'

export default {
  name: 'CategoryOfferingsSection',

  components: {
    CategoryCard,
  },

  props: {
    sectionOfferingInfo: {
      type: Object,
      required: false,
      default: null
    },
    textHomepageCategoryCoverImage: {
      type: String,
      required: false,
      default: ''
    },

  },

  computed: {
    categoryCardImage () {
      if (
        this.sectionOfferingInfo &&
        this.sectionOfferingInfo.link &&
        this.sectionOfferingInfo.link.length &&
        this.sectionOfferingInfo.link[0].enabled &&
        this.sectionOfferingInfo.link[0].media &&
        this.sectionOfferingInfo.link[0].media.media &&
        this.sectionOfferingInfo.link[0].media.enabled &&
        this.sectionOfferingInfo.link[0].media.media.url
      ) {
        return this.sectionOfferingInfo.link[0].media.media.url
      }

      return this.textHomepageCategoryCoverImage
    },
    categoryTitle () {
      if (
        this.sectionOfferingInfo &&
        this.sectionOfferingInfo.media &&
        this.sectionOfferingInfo.media.title &&
        this.sectionOfferingInfo.media.enabled &&
        this.sectionOfferingInfo.media.title.input
      ) {
        return this.sectionOfferingInfo.media.title.input
      }

      return ''
    },
    categoryDescription () {
      if (
        this.sectionOfferingInfo &&
        this.sectionOfferingInfo.richDescription &&
        this.sectionOfferingInfo.richDescription.input &&
        !this.sectionOfferingInfo.richDescription.hidden
      ) {
        return this.sectionOfferingInfo.richDescription.input
      }

      return ''
    },
    textCategorySectionBackground () {
      if (
        this.sectionOfferingInfo &&
        this.sectionOfferingInfo.media &&
        this.sectionOfferingInfo.media.media &&
        this.sectionOfferingInfo.media.enabled &&
        this.sectionOfferingInfo.media.media.url
      ) {
        return this.sectionOfferingInfo.media.media.url
      }

      return ''
    },
    title () {
      if (
        this.sectionOfferingInfo &&
        this.sectionOfferingInfo.description &&
        this.sectionOfferingInfo.description.input &&
        !this.sectionOfferingInfo.description.hidden
      ) {
        return this.sectionOfferingInfo.description.input
      }

      return ''
    }
  },
}
