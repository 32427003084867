
  import { mapState } from 'vuex'
  import TopNavbar from '@@/components/organisms/TopNavbar'
  import SiteFooter from '@@/components/organisms/SiteFooter'
  import MobileMenu from '~/components/organisms/MobileMenu'
  import Notification from '~/components/atoms/Notification'
  import WefunderBanner from '~/components/molecules/WefunderBanner'
  import SportblxPlacePage from '~/components/templates/SportblxPlacePage'

  export default {
    components: {
      Notification,
      TopNavbar,
      SiteFooter,
      MobileMenu,
      WefunderBanner,
      SportblxPlacePage
    },

    data () {
      return {
        drawer: false
      }
    },

    computed: {
      displayLogo () {
        return this.$route.path === '/'
      },
      isHome () {
        return this.$route.path === '/'
      },
      isTransparent () {
        return !(this.$route.name === 'category-subcategory-slug' && this.$route.params.slug !== undefined)
      },
      ...mapState('toggles', ['toggles']),
      ...mapState('appSettings', [
        'textCompanyName',
      ])
    },

    beforeMount () {
      if (this.$config.ENVIRONMENT.includes('production')) {
        this.addGoogleAnalyticsScript()
        this.addFreshDeskScript()
      }
    },

    methods: {
      addGoogleAnalyticsScript () {
        if (!this.$config.GOOGLE_ANALYTICS_ID) {
          return false
        }

        window.dataLayer = window.dataLayer || []

        function gtag() {
          window.dataLayer.push(arguments)
        }

        gtag('js', new Date())
        gtag('config', this.$config.GOOGLE_ANALYTICS_ID)

        const script = document.createElement('script')
        script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.$config.GOOGLE_ANALYTICS_ID}`)
        document.head.appendChild(script)
      },
      addFreshDeskScript () {
        if (!this.$config.FRESHDESK_SUPPORT_WIDGET_ID) {
          return false
        }

        window.fwSettings = {
          widget_id: this.$config.FRESHDESK_SUPPORT_WIDGET_ID
        }

        if (typeof window.FreshworksWidget !== 'function') {
          // eslint-disable-next-line no-var
          var n = function () {
              n.q.push(arguments)
          }
          // eslint-disable-next-line no-unused-expressions
          n.q = []
          window.FreshworksWidget = n
        }

        const script = document.createElement('script')
        script.setAttribute('src', `https://widget.freshworks.com/widgets/${this.$config.FRESHDESK_SUPPORT_WIDGET_ID}.js`)
        document.head.appendChild(script)
      },
      drawerChange (value) {
        this.drawer = value
      }
    },

    head() {
        return {
            title: this.textCompanyName,
        }
    }
  }
